import { OAuth } from 'skybase-oauth/oauth'
import { oAuthPermissions } from 'skybase-oauth/oauth-permissions'
import { iotHubPermissions, licensingPermissions } from '@/common/permissions'

import { messages as t } from './menu-i18n'

/**
 * Size of the screen where the menu is changed to collapsed state (by css).
 * @type {number}
 */
export const MENU_AUTO_COLLAPSING_WIDTH = 1150

export const badgeKeys = {
  IOT_HUB: 'iot-hub-notifications',
}

export const MAX_BADGE = 99

export const getMenuStructure = () => {
  const apiUrl = OAuth.config.API_ENDPOINT_URL
  const docsUrl = apiUrl?.replace('api.', 'docs.').replace('api-', 'docs-')
  return [
    {
      id: 'nav-home',
      url: '',
      title: t.home,
      icon: 'sbi-digital-hub',
      hiddenSideMenu: true,
    },
    {
      id: 'nav-iot-hub',
      url: 'iot-hub',
      title: t.iotHub,
      icon: 'sbi-nav-iot',
      badgeKey: badgeKeys.IOT_HUB,
      devTool: true,
      permission: iotHubPermissions.kiconnectDevicesRead,
      menu: [
        {
          id: 'nav-iot-hub-devices',
          url: 'devices',
          title: t.devices,
          icon: 'sbi-nav-devices',
        },
        {
          id: 'nav-iot-hub-notifications',
          url: 'notifications',
          title: t.notifications,
          icon: 'sbi-nav-notifications',
          badgeKey: badgeKeys.IOT_HUB,
        },
        {
          id: 'nav-iot-hub-about',
          url: 'about',
          title: t.about,
          icon: 'sbi-nav-info',
          subMenu: [
            { id: 'nav-iot-hub-how-to-use', url: 'how-to-use', title: t.howToUse },
            { id: 'nav-iot-hub-info', url: 'info', title: t.infoPage, hidden: OAuth.isInNormalMode },
          ],
        },
      ],
    },
    {
      id: 'nav-fleet',
      url: 'fleet',
      title: t.fleetSideBar,
      icon: 'sbi-nav-device',
      menu: [
        { id: 'fleet-configuration-root', icon: 'sbi-nav-devices', url: 'configuration', title: t.fleetConfigDevices },
        {
          id: 'my-equipment',
          icon: 'sbi-nav-components',
          url: 'my-equipment',
          title: t.myEquipment,
        },
      ],
    },
    {
      id: 'config-devices',
      url: 'configuration',
      title: t.configuration,
      icon: 'sbi-configure',
      menu: [
        { id: 'configuration-root', icon: 'sbi-nav-settings', url: 'devices', title: t.configDevices },
        { id: 'configuration-preset', icon: 'sbi-snippet', url: 'preset', title: t.presets },
      ],
    },
    {
      id: 'nav-identity-management',
      url: 'identity-management',
      title: t.identityManagement,
      icon: 'sbi-nav-user-management',
      menu: [
        {
          id: 'nav-user-management',
          url: 'user-management',
          title: t.userManagement,
          icon: 'sbi-nav-user',
          subMenu: [
            {
              id: 'nav-user-management-users',
              url: 'users',
              title: t.usersPage,
              permission: oAuthPermissions.kiconnectUsersRead,
            },
            {
              id: 'nav-user-management-roles',
              url: 'roles',
              title: t.rolesPage,
              permission: oAuthPermissions.kiconnectRolesRead,
            },
            {
              id: 'nav-user-management-tenants',
              url: 'tenants',
              title: t.tenantsPage,
              permission: oAuthPermissions.kiconnectTenantsRead,
            },
          ],
        },
        {
          id: 'nav-oauth-management',
          url: 'oauth-management',
          title: t.oAuthManagementPage,
          icon: 'sbi-oauth-clients',
          subMenu: [
            {
              id: 'nav-oauth-management-clients',
              url: 'clients',
              title: t.clientsPage,
              permission: oAuthPermissions.kiconnectClientsReadWrite,
            },
            {
              id: 'nav-oauth-management-apis',
              url: 'apis',
              title: t.apisPage,
              permission: oAuthPermissions.kiconnectApisReadWrite,
            },
          ],
        },
        {
          id: 'nav-applications',
          url: 'applications',
          title: t.applications,
          icon: 'sbi-nav-applications',
          permission: oAuthPermissions.kiconnectApplicationsRead,
        },
      ],
    },
    {
      id: 'nav-licensing',
      url: 'licensing',
      title: t.licensing,
      icon: 'sbi-key',
      permission: licensingPermissions.licensesRead,
      menu: [
        {
          url: 'management',
          title: t.management,
          icon: 'sbi-nav-licenses',
        },
        {
          url: 'about',
          title: t.about,
          icon: 'sbi-nav-info',
        },
      ],
    },
    {
      id: 'nav-docs',
      url: docsUrl,
      icon: 'sbi-file-doc',
      isExternalLink: true,
      isInternalMenuItem: true,
      title: t.documentation,
    },
  ]
}
