import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes/react-intl-prop-types'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbHint } from 'skybase-ui/skybase-components/sb-hint'
import { verticalPosition } from 'skybase-ui/skybase-components/sb-hint/constants'
import { syncProjectDeviceByDeviceId } from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { showConfirmModal } from '@/fleet-configuration/components/confirm-modal/confirm-modal-actions'
import { writeSettingsButtonSelector } from './write-settings-button-selector'
import { messages as t } from './write-settings-button-i18n'
import './write-settings-button.scss'
import { showSimpleModal } from '@/common/modals'
import { SbLoader } from 'skybase-ui/skybase-components/sb-loader'
import { loaderSize } from 'skybase-ui/skybase-components/sb-loader/constants'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { getDeviceMeasurementRunning } from '@/fleet-configuration/data-fleet/devices-measurement-running/devices-measurement-running-actions'

class _WriteSettingsButton extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    dispatch: PropTypes.func.isRequired,
    deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    deviceName: PropTypes.string.isRequired,
    isProcessingRunning: PropTypes.bool,
    isDeviceOnline: PropTypes.bool,
    isDeviceValid: PropTypes.bool,
    isDeviceKGate: PropTypes.bool,
    isDeviceSupported: PropTypes.bool,
    isDeviceBeingSynced: PropTypes.bool,
    isDeviceDirty: PropTypes.bool.isRequired,
    isDeviceSyncRequestRunning: PropTypes.bool,
    hasDeviceSyncFailed: PropTypes.bool.isRequired,
    hasSyncSucceeded: PropTypes.bool.isRequired,
    deviceLastSyncStartTime: PropTypes.string,
    projectDevice: PropTypes.object,
  }

  static defaultProps = {
    isProcessingRunning: true,
    isDeviceOnline: false,
    isDeviceValid: false,
    isDeviceSupported: false,
    isDeviceBeingSynced: true,
    isDeviceSyncRequestRunning: false,
    isDeviceKGate: false,
    deviceLastSyncStartTime: undefined,
    projectDevice: null,
  }

  constructor() {
    super()
    this.state = { isAnimationRunning: false, progressWidth: 0, animationDots: '' }
  }

  componentDidMount() {
    this.resolveAnimationState()
  }

  componentWillUnmount() {
    this.cancelAnimation()
  }

  startAnimation() {
    this.setState({ isAnimationRunning: true })
    this.frame = requestAnimationFrame(this.doUpdateAnimation)
  }

  doUpdateAnimation = () => {
    const { deviceLastSyncStartTime } = this.props
    const secondsRunning = (new Date().getTime() - new Date(deviceLastSyncStartTime).getTime()) / 1000
    const percent = this.calculateCompletedPercent(secondsRunning)
    this.setState({
      progressWidth: percent,
      animationDots: this.addDotsBySeconds(secondsRunning),
    })
    this.startAnimation()
  }

  cancelAnimation() {
    this.setState({ isAnimationRunning: false })
    cancelAnimationFrame(this.frame)
  }

  handleWriteSettingsFactory = forceWrite => async () => {
    const {
      dispatch,
      deviceId,
      intl: { formatMessage },
    } = this.props
    const result = await dispatch(syncProjectDeviceByDeviceId(deviceId, formatMessage, forceWrite))
    const { status, response } = result
    const hasResponseFailed = [409, 503].includes(status || response?.status)
    if (hasResponseFailed) {
      if ((status || response?.status) === 503) {
        this.displaySystemNotResponsiveMessage()
      } else {
        this.displayVersionIncompatibilityMessage(result)
      }
    }
  }

  handleWriteSettingsClicked = async () => {
    const {
      dispatch,
      deviceName,
      isDeviceKGate,
      projectDevice,
      intl: { formatMessage: _ },
    } = this.props

    let isRunning = false
    if (isDeviceKGate) {
      let isCanceled = false
      dispatch(
        showSimpleModal({
          title: _(t.writeSettingsToDevice),
          buttons: [],
          message: <SbLoader size={loaderSize.SM} show />,
          className: 'write-settings-confirm-modal loading',
          onClickOverlay: () => {
            dispatch(closeModal())
            isCanceled = true
          },
        }),
      )
      isRunning = await dispatch(getDeviceMeasurementRunning(projectDevice))
      if (isCanceled) {
        return
      }
    }

    dispatch(
      showConfirmModal(_, _(t.writeSettingsToDevice), this.handleWriteSettingsFactory(isDeviceKGate && isRunning), {
        modalMessage: (
          <>
            <FormattedMessage
              id="writeSettingsToDeviceConfirmation"
              defaultMessage="Are you sure you want to write configuration to device {deviceName}? This action cannot be undone."
              values={{ deviceName: <b>&quot;{deviceName}&quot;</b> }}
            />
            {isDeviceKGate && isRunning ? <span className="error">{_(t.runningMeasurementWillBeStopped)}</span> : null}
          </>
        ),
        confirmText: _(t.writeToDevice),
        className: 'write-settings-confirm-modal ',
      }),
    )
  }

  displaySystemNotResponsiveMessage() {
    console.error('Display system not responsive')
  }

  displayVersionIncompatibilityMessage(failedResponse) {
    console.error('Write settings failed', 'displayVersionIncompatibility', failedResponse)
  }

  calculateCompletedPercent(secondsRunning) {
    // exponential function where 69.7 seconds are ~ 50%,
    // and it runs slower with every new second and never reaching 100%
    return (1 - 1.01 ** -secondsRunning) * 100
  }

  addDotsBySeconds(secondsRunning) {
    return ''.padEnd((secondsRunning % 3) + 1, '.')
  }

  getHintText() {
    const {
      isProcessingRunning,
      isDeviceOnline,
      isDeviceSupported,
      isDeviceValid,
      isDeviceBeingSynced,
      intl: { formatMessage: _ },
    } = this.props

    if (isProcessingRunning) {
      return _(t.cannotWriteSettingsToDeviceWhileRunningMeasurement)
    }
    if (!isDeviceOnline) {
      return _(t.projectDeviceMustBeOnlineBeforeSync)
    }
    if (!isDeviceValid) {
      return _(t.dirtyDeviceNeedsToBeValidBeforeSynchronisation)
    }
    if (!isDeviceSupported) {
      return _(t.youNeedToEnsureDeviceIsUpdatedToLatestVersionToProceed)
    }
    if (isDeviceBeingSynced) {
      return _(t.syncAlreadyInProgress)
    }
    return ''
  }

  resolveAnimationState() {
    const { isDeviceBeingSynced } = this.props
    const { isAnimationRunning } = this.state
    // resolve outside of render function
    setTimeout(() => {
      if (isDeviceBeingSynced && !isAnimationRunning) {
        this.startAnimation()
      } else if (!isDeviceBeingSynced && isAnimationRunning) {
        this.cancelAnimation()
      }
    })
  }

  render() {
    const {
      isProcessingRunning,
      isDeviceOnline,
      isDeviceSupported,
      isDeviceValid,
      isDeviceSyncRequestRunning,
      isDeviceDirty,
      isDeviceBeingSynced,
      hasDeviceSyncFailed,
      hasSyncSucceeded,
      intl: { formatMessage: _ },
    } = this.props

    this.resolveAnimationState()

    const { progressWidth, animationDots } = this.state
    let WritingText = <>{_(t.writeToDevice)}</>
    let writingStatus = 'writing-initial'

    if (!isDeviceOnline) {
      WritingText = <>{_(t.deviceOffline)}</>
      writingStatus = 'writing-offline'
    } else if (isDeviceBeingSynced) {
      WritingText = (
        <>
          {_(t.writing)}
          <span className="animation-dots">{animationDots}</span>
        </>
      )
      writingStatus = 'writing-in-progress'
    } else if (hasDeviceSyncFailed) {
      WritingText = <>{_(t.failed)}</>
      writingStatus = 'writing-failed'
    } else if (hasSyncSucceeded && !isDeviceDirty) {
      WritingText = <>{_(t.completed)}</>
      writingStatus = 'writing-completed'
    }

    const hintData = this.getHintText()

    const writeButton = (
      <SbButton
        onClick={this.handleWriteSettingsClicked}
        className={`sb-width-ml sb-height-xs primary write-settings-button test-sync-btn ${writingStatus}`}
        disabled={
          isProcessingRunning ||
          isDeviceBeingSynced ||
          !isDeviceOnline ||
          !isDeviceValid ||
          !isDeviceSupported ||
          isDeviceSyncRequestRunning
        }
      >
        <span className="writing-percent-progress" style={{ width: `${progressWidth}%` }} />
        {WritingText}
      </SbButton>
    )

    return (
      <>
        {hintData ? (
          <SbHint position={{ vertical: verticalPosition.TOP }} hintData={hintData}>
            {writeButton}
          </SbHint>
        ) : (
          writeButton
        )}
        {isProcessingRunning && (
          <span className="component-warning sbi-alert" title={_(t.cantWriteSettingsToDeviceWhileRunningMeasurement)} />
        )}
      </>
    )
  }
}

export const WriteSettingsButton = injectIntl(connect(writeSettingsButtonSelector)(_WriteSettingsButton))
