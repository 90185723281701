import { getDevices } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import {
  getDeviceType,
  getFormattedModelNumber,
  hasLED,
  isConfigurableDevice,
} from '@/fleet-configuration/utils/device-utils'
import { canDeviceBackupByTypes } from '@/fleet-configuration/data-fleet/backup/backup-utils'
import { getProjectDeviceById } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

const getAreOfflineDevicesShown = props => {
  const offlineDeviceValue = new URLSearchParams(props?.router?.location?.search ?? '').get('offlineDevice')
  if (offlineDeviceValue) {
    return offlineDeviceValue === 'yes'
  }
  return null
}

export const ConfigurationSelector = (state, props) => {
  const allDevices = getDevices(state)
  const areOfflineDevicesShown = getAreOfflineDevicesShown(props)
  const visibleDevices = areOfflineDevicesShown ? allDevices : allDevices.filter(device => device.online)
  const devices = visibleDevices.reduce((acc, device) => {
    const projectDevice = getProjectDeviceById(state, device.id)
    // do not use devices, that are not configurable in this view
    if (!isConfigurableDevice(projectDevice)) {
      return acc
    }
    const deviceType = getDeviceType(device.types)
    const modelNumber = getFormattedModelNumber(deviceType, device.modelNumber)
    const canBackup = canDeviceBackupByTypes(device.types)
    acc.push({
      ...device,
      deviceType,
      modelNumber,
      hasLED: hasLED(deviceType),
      canBackup,
      isProjectDeviceLoaded: !!projectDevice,
      lastModifiedTimestamp: projectDevice?.lastModifiedTimestamp,
    })
    return acc
  }, [])
  return { devices, areOfflineDevicesShown }
}
