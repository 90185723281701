import { intersection } from 'lodash'
import { createSelector } from 'reselect'
import { getDevices } from '@/fleet-configuration/data-fleet/devices/devices-selectors'
import {
  hasAcquisitionDeviceType,
  isConfigurableDevice,
  getFormattedModelNumber,
  getDeviceType,
  hasLED,
} from '@/fleet-configuration/utils/device-utils'
import {
  CONFIGURABLE_DEVICE_TYPES,
  NOT_CALIBRABLE_DEVICE_TYPES,
} from '@/fleet-configuration/pages/fleet-overview/fleet-overview-constants'
import {
  getFullCatalogFromState,
  getCatalogIdByCatalogTypes,
  getConfigurationParameters,
  getComponentParametersCatalog,
} from '@/fleet-configuration/data-fleet/catalog/catalog-selectors'
import { canDeviceBackupByTypes } from '@/fleet-configuration/data-fleet/backup/backup-utils'
import { getProjectDevices } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { getDirtyStates } from '@/fleet-configuration/data-fleet/project-devices-dirty/project-devices-dirty-selector'
import { getProjectComponents } from '@/fleet-configuration/data-fleet/components/components-selectors'
import { getAllFirmwareData } from '@/fleet-configuration/data-fleet/firmware/firmware-selectors'
import { firmwareStates } from '@/fleet-configuration/data-fleet/firmware/firmware-constants'
import { getDeviceCalibrations } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-selector'
import { DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS } from '@/fleet-configuration/data-fleet/devices-calibrations/devices-calibrations-constants'

const { UPDATE_AVAILABLE, UPDATE_FINISHED_UPDATE_AVAILABLE } = firmwareStates

// can't use getProjectComponentsByFamily, because I would not be able to cache selector via reselect (which is important for this screen)
const getDeviceIdsThatAreInProject = createSelector(getProjectComponents, components => {
  return components.reduce((acc, component) => {
    if (component.family === 'device') {
      acc[component.deviceId] = true
    }
    return acc
  }, {})
})

const getAreOfflineDevicesShown = (state, props) => {
  const offlineDeviceValue = new URLSearchParams(props?.router?.location?.search ?? '').get('offlineDevice')
  if (offlineDeviceValue) {
    return offlineDeviceValue === 'yes'
  }
  return null
}

// reduce how many times devices are selected by caching this selector (by input) with reselect
const devicesSelector = createSelector(
  getDevices,
  getProjectDevices,
  getFullCatalogFromState,
  getDirtyStates, // this one is actually required even if it is not used. Otherwise this selector will get (incorrectly) cached
  (stateDevices, stateProjectDevices, stateCatalog) =>
    // it makes sense to adjust devices for our purposes here instead of in component -> free sort implementation
    stateDevices.map(device => {
      const { types = [] } = device
      const deviceType = getDeviceType(types)
      const daq = hasAcquisitionDeviceType(types)

      const devCatTypes = device.modelNumber ? device.types.concat(device.modelNumber) : device.types
      const catalogId = getCatalogIdByCatalogTypes(devCatTypes)
      const rawDeviceCatalog = stateCatalog.byId[catalogId]?.data || []
      const allCatalogTypes = rawDeviceCatalog?.map(({ type }) => type)

      const modelNumber = getFormattedModelNumber(deviceType, device.modelNumber)
      const projectDevice = stateProjectDevices.find(stateProjectDevice => device.id === stateProjectDevice.id)
      const isDirty = projectDevice?.isDirty()
      const hasVirtualModule = projectDevice?.modules?.some(module => module.isVirtual())
      const isDeviceCalibrable = !intersection(NOT_CALIBRABLE_DEVICE_TYPES, device.types).length
      const isDeviceConfigurable = !!intersection(device.types, CONFIGURABLE_DEVICE_TYPES).length
      const documentationUrl = isDeviceConfigurable
        ? getConfigurationParameters(rawDeviceCatalog, allCatalogTypes)?.deviceDocUrl
        : getComponentParametersCatalog(stateCatalog, device.modelNumber)?.deviceDocUrl ?? null

      return {
        ...device,
        modelNumber,
        deviceType,
        daq,
        isConfigurable: isConfigurableDevice(projectDevice),
        documentationUrl,
        canBackup: canDeviceBackupByTypes(types),
        isDirty,
        hasVirtualModule,
        hasProjectDevice: !!projectDevice,
        isDeviceCalibrable,
        hasLED: hasLED(deviceType),
      }
    }),
)

export const fleetOverviewSelector = createSelector(
  getAreOfflineDevicesShown,
  getDeviceIdsThatAreInProject,
  getAllFirmwareData,
  devicesSelector,
  getDeviceCalibrations,
  (areOfflineDevicesShown, deviceIdsInProject, firmwareData, devicesFromSelector, devicesCalibrations) => {
    const devicesOfflineStateResolved = areOfflineDevicesShown
      ? devicesFromSelector
      : devicesFromSelector.filter(device => device.online)
    // the device has "finished loading" it's a project device if:
    //  it's not a DAQ device (it does not support being in a project), OR
    //  we can already get project device OR
    //  it's not in project and is offline (meaning it can't be fetched into a project)
    const devicesLoadingStateResolved = devicesOfflineStateResolved.map(
      ({ id, daq, hasProjectDevice, online, ...rest }) => {
        let notifications = 0
        const firmwareState = firmwareData[id]
        if ([UPDATE_AVAILABLE, UPDATE_FINISHED_UPDATE_AVAILABLE].includes(firmwareState?.state)) {
          notifications += 1
        }
        const deviceCalibrations = devicesCalibrations.find(calibration => calibration.id === id)
        if (deviceCalibrations?.calibrationDate) {
          const calibrationExpires = new Date(deviceCalibrations.calibrationDate)
          calibrationExpires.setDate(calibrationExpires.getDate() + deviceCalibrations.calibrationIntervalDays)
          const expirationWarningDate = new Date()
          expirationWarningDate.setDate(
            expirationWarningDate.getDate() + DEVICE_CALIBRATION_PRE_EXPIRATION_WARNING_DAYS,
          )
          if (expirationWarningDate.getTime() > calibrationExpires.getTime()) {
            notifications += 1
          }
        }

        return {
          id,
          daq,
          online,
          notifications,
          isProjectDeviceLoaded: !!(!daq || hasProjectDevice || (!online && !deviceIdsInProject[id])),
          ...rest,
        }
      },
    )

    return {
      areOfflineDevicesShown,
      deviceIdsInProject,
      devices: devicesLoadingStateResolved,
      calibrations: devicesCalibrations,
    }
  },
)
