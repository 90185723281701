import { cloneDeep, isObject, isEmpty, every, each, reduce, mapValues, isPlainObject } from 'lodash'
import { createAction } from 'skybase-ui/skybase-core/base/create-action'
import { getActionName } from 'skybase-ui/skybase-core/utils/get-action-name'
import { SbEmitter } from 'skybase-ui/skybase-core/emitter'
import { batchActions } from 'redux-batched-actions'
import { guardedDeviceFactory } from '@/fleet-configuration/data-fleet/project-devices/device-factory'
import {
  isProjectDeviceLoaded,
  getProjectDeviceById,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'
import { deviceTimeSyncModes } from '@/fleet-configuration/data-fleet/device-online-status/device-online-status-constants'
import { deviceSyncStatusUpdate } from '@/fleet-configuration/data-fleet/device-sync/device-sync-actions'
import { isDeviceBeingSynced } from '@/fleet-configuration/data-fleet/device-sync/device-sync-selectors'
import {
  initialSetProjectDevice,
  loadProjectDevice,
} from '@/fleet-configuration/data-fleet/project-devices/project-devices-actions'
import { loadDeviceLastState } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-actions'
import { getDeviceLastStateById } from '@/fleet-configuration/data-fleet/devices-last-state/devices-last-state-selectors'
import { objectDiffDeep } from '@/utils/diff'
import {
  deviceSyncEventFinishedName,
  deviceSyncEventName,
  deviceSyncOperationNames,
} from '@/fleet-configuration/data-fleet/device-sync/device-sync-constants'
import { getDeviceDirtyState } from './project-devices-dirty-selector'
import { showSuccessToast } from '@/common/services/show-toast'
import { messages as t } from './project-devices-dirty-actions-i18n'

export const ADD_PROJECT_DEVICE_DIRTY = getActionName('ADD_PROJECT_DEVICE_DIRTY')
export const addProjectDevicesDirty = dirtyProps => createAction(ADD_PROJECT_DEVICE_DIRTY, dirtyProps)

export const CLEAR_PROJECT_DEVICE_DIRTY = getActionName('CLEAR_PROJECT_DEVICE_DIRTY')
export const clearProjectDeviceDirty = deviceId => createAction(CLEAR_PROJECT_DEVICE_DIRTY, { id: deviceId })

export const CLEAR_WHOLE_DEVICE_DIRTY_STATE = getActionName('CLEAR_WHOLE_DEVICE_DIRTY_STATE')
export const clearWholeDeviceDirtyState = () => createAction(CLEAR_WHOLE_DEVICE_DIRTY_STATE, {})

const DIRTY_IGNORED_PROPS = [
  'protocolIndependentId',
  'name',
  'status',
  'online',
  'housingtype',
  'types',
  'lastModifiedTimestamp',
  'deviceSpecific.controller',
  'firmwareVersion',
  'manufacturerName',
  'serialNumber',
  'samplingRates.index',
  'samplingRates.dataSourceId',
  'modules.name',
  'modules.componentId',
  'modules.catalogId',
  'modules.type',
  'modules.kistlerType',
  'modules.description',
  'modules.parametersReadable',
  'modules.parameters.changed',
  'modules.parameters.disable',
  'modules.parameters.name',
  'modules.parameters.fwVersion',
  'modules.parameters.location',
  'modules.deviceSpecific.samplingRate',
  'modules.channels.catalog',
  'modules.channels.extraTypes',
  'modules.channels.filter',
  'modules.channels.signalName',
  'modules.channels.deviceSpecific.charge',
  'modules.channels.deviceSpecific.iepe',
  'modules.channels.deviceSpecific.voltage',
  'modules.channels.parameters.scalingMethod',
  'modules.channels.parameters.dataSourceId',
  'modules.channels.parameters.order',
  'modules.channels.parameters.algorithm',
  'modules.channels.parameters.signalMode',
  'modules.channels.parameters.rmsTimeWindow',
  'modules.channels.parametersReadable',
  'modules.channels.parameters.channelSpanState',
]

const isArrayLikeObject = objectToTest => every(objectToTest, (val, key) => !isNaN(parseFloat(key)))

const _filterOutIgnoredDirtyPropsRecursive = (searchedItem, nestingPath) => {
  const baseCompareKey = nestingPath ? `${nestingPath}.` : ''
  if (isArrayLikeObject(searchedItem)) {
    each(searchedItem, (innerItem, key) => {
      const result = _filterOutIgnoredDirtyPropsRecursive(innerItem, nestingPath)
      if (isEmpty(result) && result !== true) {
        // eslint-disable-next-line no-param-reassign
        delete searchedItem[key]
      }
    })
  } else {
    each(searchedItem, (innerItem, key) => {
      const compareKey = baseCompareKey + key
      if (DIRTY_IGNORED_PROPS.includes(compareKey)) {
        // eslint-disable-next-line no-param-reassign
        delete searchedItem[key]
      } else if (isObject(innerItem)) {
        _filterOutIgnoredDirtyPropsRecursive(innerItem, compareKey)
        if (isEmpty(innerItem)) {
          // eslint-disable-next-line no-param-reassign
          delete searchedItem[key]
        }
      }
    })
  }
  return searchedItem
}

const _filterOutIgnoredPropsByCatalog = (filteredProps, originalDevice) => {
  if (filteredProps && filteredProps.modules) {
    Object.entries(filteredProps.modules).forEach(([moduleKey, filteredModule]) => {
      if (filteredModule && filteredModule.channels) {
        const originalModule = originalDevice.modules[moduleKey]
        Object.entries(filteredModule.channels).forEach(([channelKey, filteredChannel]) => {
          if (filteredChannel.parameters) {
            const originalChannel = originalModule.channels[channelKey]
            if (!isPlainObject(originalChannel)) {
              const ignoreFields = originalChannel.getExcludedFromDirtyFields()
              ignoreFields.forEach(fieldToIgnorePath => {
                // support for nested properties - first get to object to delete from
                const arrFieldToIgnore = fieldToIgnorePath.split('.')
                const lastField = arrFieldToIgnore.pop() // last item is key to actually delete

                // lodash.get does not work, because 0-length path does not return original object
                const channelParamToDeleteFrom = arrFieldToIgnore.reduce(
                  (acc, key) => (acc || {})[key],
                  filteredChannel.parameters,
                )
                if (channelParamToDeleteFrom) {
                  delete channelParamToDeleteFrom[lastField]
                }
              })
            }
          }
        })
      }
    })
  }
  return filteredProps
}

const removeDisabledDirtyChannels = (diff, nextDevice) => {
  if (!diff) {
    return diff
  }
  const activeOnly = { ...diff }
  if (diff.modules) {
    activeOnly.modules = mapValues(diff.modules, (diffModule, moduleKey) => {
      // if channels are not dirty, then there is no work. Early return
      if (!diffModule.channels) {
        return diffModule
      }
      // otherwise filter out channels that were and still are disabled
      const sourceModule = nextDevice.modules[moduleKey]
      // reduce instead of filter as object keys NEED TO BE KEPT
      return {
        ...diffModule,
        channels: reduce(
          diffModule.channels,
          (acc, diffChannel, channelKey) => {
            const nextChannel = sourceModule.channels[channelKey]
            if (!nextChannel || nextChannel.parameters.enabled) {
              acc[channelKey] = diffChannel
            } else if (diffChannel.parameters && diffChannel.parameters.enabled) {
              acc[channelKey] = { parameters: { enabled: true } }
            }
            return acc
          },
          {},
        ),
      }
    })
  }
  return activeOnly
}

// based on reverse engineered device behaviour which applies only to min and max values
const areMinMaxValuesSimilar = (rawSourceValue, rawActualValue) => {
  const sourceValue = parseFloat(rawSourceValue)
  const actualValue = parseFloat(rawActualValue)
  if ((sourceValue || sourceValue === 0) && (actualValue || actualValue === 0)) {
    return sourceValue.toFixed(5) === actualValue.toFixed(5)
  }
  return false
}

const isFilterFrequency1Used = nextChannel => nextChannel.isUsingFilter && nextChannel.isUsingFilter()

const isFilterFrequency2Used = nextChannel => (nextChannel.parameters || {}).filterType === 'Bandpass'

const filterOutSimilarParametersWithRoundOffError = (diff, sourceDevice, actualDevice) => {
  if (!diff) {
    return diff
  }
  const activeOnly = { ...diff }
  if (diff.modules) {
    activeOnly.modules = mapValues(diff.modules, (diffModule, moduleKey) => {
      // if channels are not dirty, then there is no work. Early return
      if (!diffModule.channels) {
        return diffModule
      }
      // otherwise filter out channels that were and still are disabled
      const sourceModule = sourceDevice.modules[moduleKey]
      const actualModule = actualDevice.modules[moduleKey]
      return {
        ...diffModule,
        channels: reduce(
          diffModule.channels,
          (acc, diffChannel, channelKey) => {
            acc[channelKey] = {
              ...diffChannel,
              parameters: diffChannel.parameters ? { ...diffChannel.parameters } : {},
            }
            const sourceChannel = (sourceModule && sourceModule.channels[channelKey]) || {}
            const actualChannel = (actualModule && actualModule.channels[channelKey]) || {}
            const [srcMinValue, srcMaxValue] = (sourceChannel.parameters || {}).physicalRange || []
            const [actMinValue, actMaxValue] = (actualChannel.parameters || {}).physicalRange || []
            if (acc[channelKey].parameters.physicalRange) {
              if (areMinMaxValuesSimilar(srcMinValue, actMinValue)) {
                delete acc[channelKey].parameters.physicalRange[0]
              }
              if (areMinMaxValuesSimilar(srcMaxValue, actMaxValue)) {
                delete acc[channelKey].parameters.physicalRange[1]
              }
              if (
                acc[channelKey].parameters.physicalRange &&
                acc[channelKey].parameters.physicalRange[0] === undefined &&
                acc[channelKey].parameters.physicalRange[1] === undefined
              ) {
                delete acc[channelKey].parameters.physicalRange
              }
            }
            if (acc[channelKey].parameters.sensitivity) {
              if (
                Number(sourceChannel.parameters.sensitivity).toPrecision(6) ===
                Number(actualChannel.parameters.sensitivity).toPrecision(6)
              ) {
                delete acc[channelKey].parameters.sensitivity
              }
            }

            if (!isFilterFrequency1Used(actualChannel)) {
              delete acc[channelKey].parameters.filterFreq1
              delete acc[channelKey].parameters.order
              delete acc[channelKey].parameters.algorithm
              delete acc[channelKey].parameters.qualityFactor
              delete acc[channelKey].parameters.sampleCount
              if (acc[channelKey].validationRules) {
                delete acc[channelKey].validationRules['parameters.filterFreq1']
              }
            }

            if (!isFilterFrequency2Used(actualChannel)) {
              delete acc[channelKey].parameters.filterFreq2
              if (acc[channelKey].validationRules) {
                delete acc[channelKey].validationRules['parameters.filterFreq2']
              }
            }
            return acc
          },
          {},
        ),
      }
    })
  }
  return activeOnly
}

export const filterOutIgnoredDirtyProps = (propsToFilter, device) => {
  let filtered = cloneDeep(propsToFilter)
  filtered = _filterOutIgnoredPropsByCatalog(filtered, device)
  // specifically delete mark about dirty sampling rate on virtual module (as it is not in lastKnownState)
  if (device.isLabAmp() && filtered?.modules?.[0]?.samplingRate) {
    delete filtered.modules[0].samplingRate
  }
  filtered = _filterOutIgnoredDirtyPropsRecursive(filtered, '')
  return isEmpty(filtered) ? undefined : filtered
}

export const removeUnsupportedModules = (diff, device) => {
  if (!diff) {
    return diff
  }
  const supportedModules = { ...diff }
  supportedModules.modules = mapValues(diff.modules, (diffModule, moduleKey) => {
    const sourceModule = device.modules[moduleKey]
    return sourceModule.isSupported() ? diffModule : {}
  })
  return supportedModules
}

const convertStructureLeafsToTrue = structure =>
  reduce(
    structure,
    (prev, value, key) => {
      if (value && typeof value === 'object') {
        prev[key] = convertStructureLeafsToTrue(value)
      } else {
        prev[key] = true
      }
      return prev
    },
    {},
  )

const removeUnusedClockSettingsDiff = (dirtyProps, actualDevice) => {
  if (dirtyProps.clockSettings) {
    const result = { ...dirtyProps, clockSettings: { ...dirtyProps.clockSettings } }
    if (dirtyProps.clockSettings.ptp && actualDevice.clockSettings.syncMode !== deviceTimeSyncModes.PTP) {
      delete result.clockSettings.ptp
    }
    if (dirtyProps.clockSettings.ntp && actualDevice.clockSettings.syncMode !== deviceTimeSyncModes.NTP) {
      delete result.clockSettings.ntp
    }
    return result
  }
  return dirtyProps
}

const hasDirtyPropsChanged = (originalDirtyProps, newDirtyProps) => {
  // if both falsy, then definitely no change
  if (!originalDirtyProps && !newDirtyProps) {
    return false
  }
  // if both truthy, we need to deep compare (objects) to say whether it changed
  if (originalDirtyProps && newDirtyProps) {
    return Object.keys(objectDiffDeep(originalDirtyProps, newDirtyProps)).length
  }
  // otherwise one is truthy and another one is falsy => change occurred
  return true
}

const dirtyLoading = {}
export const setProjectDeviceDirtyProps = deviceId => async (dispatch, getState) => {
  // deduplicate when dirty recalculations were queried more then once
  if (dirtyLoading[deviceId]) {
    return
  }
  let state = getState()
  const device = getProjectDeviceById(state, deviceId)
  let originalDevice = getDeviceLastStateById(state, deviceId)
  if (!originalDevice) {
    dirtyLoading[deviceId] = true
    // try to recover if setting dirty happened before device last state was loaded
    await dispatch(loadDeviceLastState(deviceId))
    // deduplicate all the other calls + also everything that is already in callback loop (hence new setTimeout)
    setTimeout(() => {
      delete dirtyLoading[deviceId]
    })
    state = getState()
    originalDevice = getDeviceLastStateById(state, deviceId)
  }
  if (device.hasSameModulesAs(originalDevice)) {
    const diff = convertStructureLeafsToTrue(objectDiffDeep(originalDevice, device))
    const clockSettingsProcessedDiff = removeUnusedClockSettingsDiff(diff, device)
    const supportedModulesDiff = removeUnsupportedModules(clockSettingsProcessedDiff, device)
    const activeChannelsDiff = removeDisabledDirtyChannels(supportedModulesDiff, device)
    const similarParamsCleanDiff = filterOutSimilarParametersWithRoundOffError(
      activeChannelsDiff,
      originalDevice,
      device,
    )

    const dirtyProps = filterOutIgnoredDirtyProps(similarParamsCleanDiff, device)
    const originalDeviceDirtyProps = getDeviceDirtyState(state, device.id)
    const projectDevicesDirtyAction = addProjectDevicesDirty({
      [device.id]: dirtyProps === undefined ? null : dirtyProps,
    })
    // if there was change in dirty state, then reset sync result
    if (hasDirtyPropsChanged(originalDeviceDirtyProps, dirtyProps)) {
      dispatch(
        batchActions([
          ...(isDeviceBeingSynced(state, device.id) ? [] : [deviceSyncStatusUpdate({ deviceId: device.id })]),
          projectDevicesDirtyAction,
        ]),
      )
      return
    }
    dispatch(projectDevicesDirtyAction)
  } else {
    console.error(
      'Last sync state (1st arg) and actual project (2nd arg) have different number of modules',
      originalDevice,
      device,
    )
    dispatch(addProjectDevicesDirty({ [device.id]: null }))
  }
}

const loadProjectDeviceDirty = deviceId => async (dispatch, getState) => {
  await dispatch(loadDeviceLastState(deviceId))
  const deviceLastState = getDeviceLastStateById(getState(), deviceId)
  dispatch(setProjectDeviceDirtyProps(deviceLastState.id))
  const deviceModel = guardedDeviceFactory(dispatch, getState, getProjectDeviceById(getState(), deviceLastState.id))
  dispatch(initialSetProjectDevice(deviceModel))
}

SbEmitter.on(deviceSyncEventName, async (message, dispatch, getState) => {
  const { operationStatusCode, operationName, deviceId } = message
  const isSyncEventInCorrectEndState =
    operationStatusCode === '200' || (operationName === deviceSyncOperationNames.STARTED && !operationStatusCode)
  if (!isSyncEventInCorrectEndState) {
    if (operationStatusCode) {
      if (operationName === deviceSyncOperationNames.ENDED) {
        // mark end of (failed) sync
        SbEmitter.emit(deviceSyncEventFinishedName, message)
      }
      console.error('Device sync failed: ', message)
    }
    return
  }

  let isProjectDeviceReloaded = false
  if (!isProjectDeviceLoaded(getState(), deviceId)) {
    await dispatch(loadProjectDevice(deviceId))
    isProjectDeviceReloaded = true
  }
  const state = getState()
  const isSyncInActiveProject = !!getProjectDeviceById(state, deviceId)
  if (!isSyncInActiveProject) {
    return
  }

  if (operationName === deviceSyncOperationNames.ENDED) {
    showSuccessToast(t.writeSettingsSucceeded)

    // refresh project device after sync is finished
    if (!isProjectDeviceReloaded) {
      await dispatch(loadProjectDevice(deviceId))
    }
    await dispatch(loadProjectDeviceDirty(deviceId))
  }

  // mark end of sync
  SbEmitter.emit(deviceSyncEventFinishedName, message)
})
