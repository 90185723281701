export const DEVICE_TYPE = {
  KIDAQ: 'KiDAQ',
  LABAMP: 'LabAmp',
  FORCE_PLATE: 'ForcePlate',
  BIO_SYNC_BOX: 'SyncBox',
  BIO_DAQ_BOX: 'DaqBox',
  BIO_START_BLOCK: 'StartBlock',
}

export const ACQUISITION_DEVICE_TYPE = 'x.com.kistler.acquisition.device'

export const KGATE_DEVICE_TYPE = 'x.com.kistler.kgate'
export const LABAMP_DEVICE_TYPE = 'x.com.kistler.labamp'
export const FORCE_PLATE_DEVICE_TYPE = 'x.com.kistler.kiconnect.device.biodigital.forceplate'
export const BIO_SYNC_BOX_DEVICE_TYPE = 'x.com.kistler.kiconnect.device.biodigital.syncbox'
export const BIO_DAQ_BOX_DEVICE_TYPE = 'x.com.kistler.kiconnect.device.biodigital.daq'
export const BIO_START_BLOCK_DEVICE_TYPE = 'x.com.kistler.kiconnect.device.biodigital.startingblock'
