/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  configuration: {
    id: 'configuration.configuration',
    defaultMessage: 'Configuration',
  },
  home: {
    id: 'configuration.home',
    defaultMessage: 'Home',
  },
  noDevicesFound: {
    id: 'configuration.noDevicesFound',
    defaultMessage: 'No devices found',
  },
  showOfflineDevices: {
    id: 'configuration.showOfflineDevices',
    defaultMessage: 'Show Offline Devices',
  },
  configureDevice: {
    id: 'configuration.configureDevice',
    defaultMessage: 'Configure device',
  },
  identifyDevice: {
    id: 'configuration.identifyDevice',
    defaultMessage: 'Identify device',
  },
  identifyCalled: {
    id: 'configuration.identifyCalled',
    defaultMessage: 'Identify called',
  },
  identifyCantBeCalledOnOfflineDevice: {
    id: 'configuration.identifyCantBeCalledOnOfflineDevice',
    defaultMessage: "Identify can't be called on offline device",
  },
  backupDeviceConfiguration: {
    id: 'configuration.backupDeviceConfiguration',
    defaultMessage: 'Backup device configuration',
  },
  configurationBackupRestoreNotSupported: {
    id: 'configuration.theConfigurationBackupRestoreIsNotSupportedAtThisTypeOfDevice',
    defaultMessage: 'The Configuration Backup/Restore is not supported at this type of device',
  },
  devices: {
    id: 'configuration.devices',
    defaultMessage: 'Devices',
  },
  presets: {
    id: 'configuration.presets',
    defaultMessage: 'Presets',
  },
  lastModified: {
    id: 'configuration.lastModified',
    defaultMessage: 'Last Modified',
  },
  deviceConfiguration: {
    id: 'configuration.deviceConfiguration',
    defaultMessage: 'Device Configuration',
  },
  failedToLoadConfigurationDevices: {
    id: 'configuration.failedToLoadConfigurationDevices',
    defaultMessage: 'Failed to load configuration devices',
  },
})
