import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

// I can't use SbMenuItem as it prepends base tag's href attribute (force-creating relative url).
//  So this component basically creates the same HTML but fixes this problem
export const MenuItemExternalLink = ({ id, url, title, icon, className = null }) => {
  const { formatMessage: _ } = useIntl()
  return (
    <li id={id} className={`sb-list-menu-item ${className}`} key={`menu-item-${url}`}>
      <div className="sb-menu-item">
        <a href={url} target="_blank" rel="noreferrer">
          <span className="sb-menu-icon">
            <i className={icon} />
          </span>
          <span>{_(title)}</span>
        </a>
      </div>
      <div className="sb-after-link" />
    </li>
  )
}

MenuItemExternalLink.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
}
