import { createSelector } from 'reselect'
import {
  getDeviceType,
  hasAcquisitionDeviceType,
  isConfigurableDevice,
  getFormattedModelNumber,
} from '@/fleet-configuration/utils/device-utils'
import { getSnippetFilter } from '@/fleet-configuration/data-fleet/snippets/snippets-selectors'
import { getProjectDevices } from '@/fleet-configuration/data-fleet/project-devices/project-devices-selectors'

export const snippetsTableModalSelector = createSelector(
  getProjectDevices,
  getSnippetFilter,
  (projectDevices, filterValues) => ({
    // it makes sense to adjust devices for our purposes here instead of in component -> free sort implementation
    devices: projectDevices
      ? projectDevices
          .filter(device => hasAcquisitionDeviceType(device.types || []) && isConfigurableDevice(device))
          .map(device => {
            const { types = [] } = device
            const deviceType = getDeviceType(types)
            const daq = true

            const modelNumber = getFormattedModelNumber(deviceType, device.modelNumber)
            return { ...device, modelNumber, deviceType, daq }
          })
      : [],
    filterValues,
  }),
)
