import {
  BIO_DAQ_BOX_DEVICE_TYPE,
  BIO_START_BLOCK_DEVICE_TYPE,
  BIO_SYNC_BOX_DEVICE_TYPE,
  KGATE_DEVICE_TYPE,
  LABAMP_DEVICE_TYPE,
} from '@/common/device-type-constants'

export const CONFIGURABLE_DEVICE_TYPES = [KGATE_DEVICE_TYPE, LABAMP_DEVICE_TYPE]
export const NOT_CALIBRABLE_DEVICE_TYPES = [
  KGATE_DEVICE_TYPE,
  BIO_SYNC_BOX_DEVICE_TYPE,
  BIO_START_BLOCK_DEVICE_TYPE,
  BIO_DAQ_BOX_DEVICE_TYPE,
]
